<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <router-link  v-if="getUser.appointee_id" to="/nueva/tratamiento/doctor">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            aria-controls="sidebar-add-new-event"

            variant="primary"
            block

        >
          Agregar Cita Tratamiento
        </b-button>
      </router-link>
      <router-link  v-else to="/nueva/tratamiento/recepcion">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            aria-controls="sidebar-add-new-event"

            variant="primary"
            block

        >
          Agregar Cita Tratamiento
        </b-button>
      </router-link>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendario</span>
        </h5>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import {mapActions, mapGetters} from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    emit_change: Function,
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },
}
</script>

<style>
.list-docts{
  overflow: scroll;
  height: 350px;
  padding: 30px;
}

</style>
