import axios from '@axios'

//let URL_API_AXIOS = process.env.NODE_ENV === 'development' ? 'http://localhost:4029' : 'https://qa.desk.onkimia.com.mx';
let URL_API_AXIOS = process.env.NODE_ENV === 'development' ? 'http://localhost:4029' : 'https://desk.onkimia.com.mx';
import store from '../../store';
import {requestFetch} from "@/store/config";

export default {
    namespaced: true,
    state: {
        calendarOptions: [],
        selectedCalendars: [],
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedCalendars = val
        },
        SET_DOCTOR(state, val) {
            state.calendarOptions = val
        },
        SET_DOCTOR_CALENDARS(state, val) {
            state.selectedCalendars = val
        },
    },
    actions: {
        async findAppointment({commit}) {
            const response = await requestFetch(`/api/doctor/calendar/place`, 'GET');
            let doctor = []
            for (const a of response.doctors) {
                doctor.push({
                    color: 'primary',
                    label: a.ResPartnerAppointment.name,
                    id: a.ResPartnerAppointment.id,

                })
            }

            commit('SET_DOCTOR',doctor)
        },
        async findAppointmentFilter({commit},calendars) {
            let doctor = []
            if(calendars === undefined || calendars === null){
                const response = await requestFetch(`/api/doctor/calendar/place`, 'GET');

                for (const a of response.doctors) {
                    doctor.push(
                        a.ResPartnerAppointment.id
                    )
                }
            }else{
                doctor = calendars.calendars

            }
            commit('SET_DOCTOR_CALENDARS',doctor)
        },
        fetchEvents(ctx, {calendars}) {
            const medical_id = store.getters["auth/getUser"].appointee_id;
            if (medical_id !== null){
                calendars = [medical_id]
            }
            let config = {
                headers: {
                    'Authorization': store.getters["auth/getToken"]
                }
            }
            return new Promise((resolve, reject) => {
                axios
                    .get(URL_API_AXIOS + `/api/appointment/all/${calendars}`, config)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addEvent(ctx, {event}) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/apps/calendar/events', {event})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateEvent(ctx, {event}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/apps/calendar/events/${event.id}`, {event})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeEvent(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/apps/calendar/events/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
